import axios from "axios";

async function login(user_name, password) {
    try {
        return await axios.post(
            `${process.env.REACT_APP_API_URL}/users/login`, {
                user_name,
                password,
            }
        );
    } catch (err) {
        throw err;
    }
}

export default login;